import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "items-center" },
          [
            _c(VProgressCircular, {
              attrs: { indeterminate: "", size: "60", color: "primary" },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              VForm,
              {
                ref: "form",
                staticClass: "form",
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.nexStep($event)
                  },
                },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c("div", [
                  _vm.editable
                    ? _c("div", [
                        _c("div", { staticClass: "portfolio-size-container" }, [
                          _vm.userType === _vm.UserTypeEnum.Admin
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "portfolio-size-currency-container",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "portfolio-size-currency-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("currency")))]
                                  ),
                                  _c(VSelect, {
                                    staticClass:
                                      "portfolio-size-currency-input",
                                    attrs: {
                                      rules: [_vm.required()],
                                      items: _vm.currencyOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: "",
                                      color: "primary",
                                    },
                                    on: {
                                      change: _vm.changePortfolioSizeCurrency,
                                    },
                                    model: {
                                      value: _vm.User.CurrencyPreference,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.User,
                                          "CurrencyPreference",
                                          $$v
                                        )
                                      },
                                      expression: "User.CurrencyPreference",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "portfolio-size-content" },
                            [
                              _c("span", { staticClass: "section-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("yearly_investment_question"))
                                ),
                              ]),
                              _c("span", { staticClass: "min-value" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("min_value", {
                                      value: _vm.getMinInvestmentValue(),
                                    })
                                  )
                                ),
                              ]),
                              _c("money", {
                                staticClass: "portfolio-size-input-money",
                                class: [
                                  _vm.User.InvestValue >
                                  _vm.portfolioMinInvestValue
                                    ? "field"
                                    : "fieldRequired",
                                ],
                                staticStyle: { "padding-left": "10px" },
                                attrs: {
                                  "data-test": "Alpha:Preferences:InvestValue",
                                  prefix: _vm.portfolioValueInputPrefix(),
                                  thousands: ".",
                                  decimal: ",",
                                  min: "1",
                                  rules: [
                                    function (v) {
                                      return (
                                        v >= _vm.portfolioMinInvestValue ||
                                        _vm.required()
                                      )
                                    },
                                  ],
                                },
                                nativeOn: {
                                  change: function ($event) {
                                    return _vm.putPortfolioValue(
                                      _vm.User.InvestValue
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.User.InvestValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.User, "InvestValue", $$v)
                                  },
                                  expression: "User.InvestValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        this.showNotEnoughMessage
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "13px",
                                  display: "flex",
                                  color: "red",
                                  "margin-left": "20px",
                                  "margin-top": "4px",
                                },
                                attrs: {
                                  "data-test":
                                    "Alpha:UserPreferences:SpanNotEnough",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("not_enough", {
                                      value:
                                        "" +
                                        (_vm.PrivatePartner ? "50k" : "200k"),
                                    })
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "preference-portfolio-value-non-editable",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("preferences_portfolio_value"))
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "preference-portfolio-value-non-editable",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatToCurrency(
                                  _vm.User.CurrencyPreference,
                                  _vm.User.InvestValue
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                  _c(
                    "div",
                    { staticClass: "section" },
                    [
                      _c("div", { staticClass: "section-header" }, [
                        _c("span", { staticClass: "section-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("sectors_interest")) + " "
                          ),
                        ]),
                        _c("span", { staticClass: "section-subtitle" }, [
                          _vm._v(_vm._s(_vm.$t("select_all_you_want"))),
                        ]),
                      ]),
                      _c(VCheckbox, {
                        staticClass: "no-preference black-label mt-2 pa-0",
                        attrs: {
                          "data-test":
                            "Alpha:Preferences:InvestmentsSectors:NoPreference",
                          dense: "",
                          filled: "",
                          "hide-details": "",
                        },
                        on: { change: _vm.noPreferenceSectorsCheckboxChanged },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "checkbox-label" }, [
                                  _vm._v(_vm._s(_vm.$t("no_preferences"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.noPreferenceSector,
                          callback: function ($$v) {
                            _vm.noPreferenceSector = $$v
                          },
                          expression: "noPreferenceSector",
                        },
                      }),
                      _vm._l(
                        _vm.investmentsSectors,
                        function (checkbox, index) {
                          return _c(VCheckbox, {
                            key: index,
                            staticClass: "black-label mt-2 pa-0",
                            staticStyle: { color: "var(--primary)" },
                            attrs: {
                              "data-test":
                                "Alpha:Preferences:InvestmentsSectors:" +
                                checkbox.text,
                              dense: "",
                              filled: "",
                              disabled: checkbox.disabled,
                              rules: [
                                function (v) {
                                  return (
                                    _vm.investmentsSectors.filter(function (x) {
                                      return x.checked
                                    }).length != 0
                                  )
                                } || _vm.required,
                              ],
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "checkbox-label" },
                                        [_vm._v(_vm._s(_vm.$t(checkbox.text)))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: checkbox.checked,
                              callback: function ($$v) {
                                _vm.$set(checkbox, "checked", $$v)
                              },
                              expression: "checkbox.checked",
                            },
                          })
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "company-size" },
                      [
                        _c("div", { staticClass: "section-header" }, [
                          _c("span", { staticClass: "section-title" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("company_size_question")) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          VRadioGroup,
                          {
                            attrs: {
                              rules: [_vm.required()],
                              "data-test":
                                "Alpha:UserPreferences:DivCompanySize",
                            },
                            model: {
                              value: _vm.User.CompanyRevenue,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "CompanyRevenue", $$v)
                              },
                              expression: "User.CompanyRevenue",
                            },
                          },
                          _vm._l(_vm.companySize, function (size, index) {
                            return _c(VRadio, {
                              key: index,
                              attrs: {
                                value: size.value,
                                "data-test":
                                  "Alpha:Preferences:CompanyRevenue:" +
                                  size.text,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox-container" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "checkbox-label" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t(size.text))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "checkbox-subtitle",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(size.subtitle)
                                                    )
                                                ),
                                              ]
                                            ),
                                            size.recommended
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "checkbox-recommended",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("recomended")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "investor-type" },
                      [
                        _c("div", { staticClass: "section-header" }, [
                          _c("span", { staticClass: "section-title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("investment_desire")) + " "
                            ),
                          ]),
                          _c("span", { staticClass: "section-subtitle" }, [
                            _vm._v(_vm._s(_vm.$t("select_all_you_want"))),
                          ]),
                        ]),
                        _vm._l(
                          _vm.investIntendedPersonType,
                          function (checkbox, index) {
                            return _c(VCheckbox, {
                              key: index,
                              staticClass: "black-label mt-2 pa-0",
                              style:
                                _vm.disabledCheckBoxPersonType(checkbox) &&
                                "color: var(--primary)",
                              attrs: {
                                disabled:
                                  _vm.disabledCheckBoxPersonType(checkbox),
                                dense: "",
                                filled: "",
                                rules: [
                                  function (v) {
                                    return (
                                      _vm.investIntendedPersonType.filter(
                                        function (x) {
                                          return x.checked
                                        }
                                      ).length != 0
                                    )
                                  } || _vm.required,
                                ],
                                "hide-details": "",
                                "data-test":
                                  "Alpha:Preferences:InvestmentDesire:" +
                                  checkbox.text,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.investIntendedPersonTypeChange(
                                    checkbox
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "checkbox-label-flex",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "checkbox-label" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t(checkbox.text))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "checkbox-label-callback",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      checkbox.textCallback
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: checkbox.checked,
                                callback: function ($$v) {
                                  _vm.$set(checkbox, "checked", $$v)
                                },
                                expression: "checkbox.checked",
                              },
                            })
                          }
                        ),
                        _vm.showCNPJ
                          ? _c(
                              "label",
                              {
                                staticClass: "mt-8",
                                staticStyle: {
                                  display: "block",
                                  color: "var(--dark)",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.User.Nationality === "Brazil"
                                      ? _vm.$t("CNPJ")
                                      : _vm.$t("ein")
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showCNPJ && _vm.User.Nationality === "Brazil"
                          ? _c(VTextField, {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##.###.###/####-##"],
                                  expression: "['##.###.###/####-##']",
                                },
                              ],
                              staticClass: "my-input-style",
                              staticStyle: { color: "var(--dark)" },
                              attrs: {
                                color: "dark",
                                dense: "",
                                placeholder: "00.000.000/0000-00",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.check_social_number()
                                },
                              },
                              model: {
                                value: _vm.investorCNPJ,
                                callback: function ($$v) {
                                  _vm.investorCNPJ = $$v
                                },
                                expression: "investorCNPJ",
                              },
                            })
                          : _vm._e(),
                        _vm.showCNPJ && _vm.User.Nationality !== "Brazil"
                          ? _c(VTextField, {
                              staticClass: "my-input-style",
                              staticStyle: { color: "var(--dark)" },
                              attrs: {
                                placeholder: "EIN",
                                color: "dark",
                                dense: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.check_social_number()
                                },
                              },
                              model: {
                                value: _vm.investorCNPJ,
                                callback: function ($$v) {
                                  _vm.investorCNPJ = $$v
                                },
                                expression: "investorCNPJ",
                              },
                            })
                          : _vm._e(),
                        !!_vm.isNotValid && _vm.isNotValid.value
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "red",
                                  margin: "0px 0px 0px 12px",
                                  "font-size": "12px",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(_vm.isNotValid.message)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showCNPJ
                          ? _c(
                              "label",
                              {
                                staticClass: "mt-2",
                                staticStyle: {
                                  display: "block",
                                  color: "var(--dark)",
                                  "text-transform": "uppercase",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("trading_name")))]
                            )
                          : _vm._e(),
                        _vm.showCNPJ
                          ? _c(VTextField, {
                              staticClass: "my-input-style",
                              staticStyle: { color: "var(--dark)" },
                              attrs: {
                                color: "dark",
                                dense: "",
                                placeholder: "Nome",
                              },
                              model: {
                                value: _vm.investorCNPJName,
                                callback: function ($$v) {
                                  _vm.investorCNPJName = $$v
                                },
                                expression: "investorCNPJName",
                              },
                            })
                          : _vm._e(),
                        _vm.showCNPJ &&
                        _vm.InvestorInvestIntendedPersonListPj &&
                        _vm.InvestorInvestIntendedPersonListPj.length > 0
                          ? _c(
                              VCard,
                              { staticClass: "mt-0" },
                              [
                                _c(VSimpleTable, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.User.Nationality === "Brazil"
                                                ? _vm.$t("CNPJ")
                                                : _vm.$t("ein")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("trading_name"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("edit"))),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.InvestorInvestIntendedPersonListPj,
                                      function (item) {
                                        return _c("tr", { key: item }, [
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatCnpj(
                                                      item.SocialNumberOfInvestmentVehicle
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.TradingName
                                                      ? item.TradingName
                                                      : "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "px-2 ml-1 secondary",
                                                  attrs: {
                                                    "min-width": "0",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditCNPJ(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-pencil")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "card-actions" },
                      [
                        _c("DxaButton", {
                          staticClass: "ma-0 mt-5 next-btn",
                          staticStyle: { width: "300px" },
                          attrs: {
                            "data-test": "Alpha:Preferences:BtnNextStep",
                            title: _vm.$t("" + _vm.btnText),
                            loading: _vm.loading,
                          },
                          on: {
                            function: function ($event) {
                              return _vm.checkForm()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      VDialog,
                      {
                        attrs: { persistent: "", "max-width": "450" },
                        model: {
                          value: _vm.socialNumberDialog,
                          callback: function ($$v) {
                            _vm.socialNumberDialog = $$v
                          },
                          expression: "socialNumberDialog",
                        },
                      },
                      [
                        _c(
                          VCard,
                          {
                            staticStyle: {
                              "border-radius": "10px",
                              "background-color": "var(--white) !important",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "social-modal-header" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    attrs: { color: "black" },
                                    on: {
                                      click: function ($event) {
                                        _vm.socialNumberDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-close")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "h2",
                              {
                                staticStyle: {
                                  color: "var(--primary)",
                                  "text-align": "center",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc("social_number_already_exists", 2)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "px-12 text-center",
                                staticStyle: { color: "var(--dark)" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc("if_social_number_is_yours", 2)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticClass: "mb-5",
                                    staticStyle: {
                                      "border-radius": "10px",
                                      "border-style": "groove",
                                      "border-color": "var(--primary)",
                                      "background-color": "var(--white)",
                                      color: "var(--primary)",
                                    },
                                    attrs: {
                                      outlined: "",
                                      height: "35",
                                      width: "124",
                                    },
                                    on: { click: _vm.goToLogin },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("login")))]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.openDialogEditCNPJ && _vm.showCNPJ
                      ? _c(
                          VDialog,
                          {
                            model: {
                              value: _vm.openDialogEditCNPJ,
                              callback: function ($$v) {
                                _vm.openDialogEditCNPJ = $$v
                              },
                              expression: "openDialogEditCNPJ",
                            },
                          },
                          [
                            _c(
                              VCard,
                              { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
                              [
                                _c(VCardTitle, { staticClass: "mb-2" }, [
                                  _c(
                                    "h4",
                                    { staticClass: "dxa_modal_title h4" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("edit") +
                                              " " +
                                              (_vm.CNPJToEdit.tradingName
                                                ? _vm.CNPJToEdit.tradingName
                                                : "")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  VCardText,
                                  [
                                    _c(
                                      VForm,
                                      {
                                        ref: "formEditCNPJ",
                                        on: {
                                          submit: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.editCNPJSubmit($event)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VCol,
                                          { staticClass: "mb-2" },
                                          [
                                            _c(
                                              VRow,
                                              [
                                                _vm.User.Nationality ===
                                                "Brazil"
                                                  ? _c(VTextField, {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: [
                                                            "##.###.###/####-##",
                                                          ],
                                                          expression:
                                                            "['##.###.###/####-##']",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "my-input-style",
                                                      staticStyle: {
                                                        color: "var(--dark)",
                                                      },
                                                      attrs: {
                                                        color: "dark",
                                                        dense: "",
                                                        rules: [_vm.required()],
                                                        placeholder:
                                                          "00.000.000/0000-00",
                                                        label: _vm.$t("CNPJ"),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.CNPJToEdit.CNPJ,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CNPJToEdit,
                                                            "CNPJ",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CNPJToEdit.CNPJ",
                                                      },
                                                    })
                                                  : _c(VTextField, {
                                                      staticClass:
                                                        "my-input-style",
                                                      staticStyle: {
                                                        color: "var(--dark)",
                                                      },
                                                      attrs: {
                                                        color: "dark",
                                                        dense: "",
                                                        rules: [_vm.required()],
                                                        placeholder: "EIN",
                                                        label: _vm.$t("ein"),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.CNPJToEdit.CNPJ,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CNPJToEdit,
                                                            "CNPJ",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CNPJToEdit.CNPJ",
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VCol,
                                          [
                                            _c(
                                              VRow,
                                              [
                                                _c(VTextField, {
                                                  staticClass: "pt-0",
                                                  staticStyle: {
                                                    color: "var(--dark)",
                                                  },
                                                  attrs: {
                                                    rules: [_vm.required()],
                                                    label:
                                                      _vm.$t("trading_name"),
                                                    color: "dark",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.CNPJToEdit
                                                        .tradingName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.CNPJToEdit,
                                                        "tradingName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "CNPJToEdit.tradingName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VCardActions,
                                          {
                                            staticClass:
                                              "pl-0 dxa_modal_actions",
                                          },
                                          [
                                            _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "dxa_modal_btnSuccess",
                                                attrs: {
                                                  color: "primary",
                                                  "min-width": "100",
                                                  loading: _vm.loading,
                                                  type: "submit",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("save")))]
                                            ),
                                            _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "dxa_modal_btnError",
                                                attrs: {
                                                  color: "red",
                                                  "min-width": "100",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.openDialogEditCNPJ = false
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("cancel")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.alertDialogAddCNPJ
                                      ? _c(
                                          VAlert,
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              type: _vm.alertDialogAddCNPJType,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(_vm.alertDialogAddCNPJ)
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              VDialog,
              {
                attrs: { "max-width": "564" },
                model: {
                  value: _vm.errorRequiredData,
                  callback: function ($$v) {
                    _vm.errorRequiredData = $$v
                  },
                  expression: "errorRequiredData",
                },
              },
              [
                _c("AlertModalRequiredData", {
                  attrs: { requiredDataList: _vm.errorRequiredDataItems },
                  on: {
                    closeDialog: function ($event) {
                      _vm.errorRequiredData = false
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }