import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      staticClass: "btn",
      staticStyle: { "z-index": "10", color: "var(--white)" },
      attrs: { color: "primary", loading: _vm.loading },
      on: { click: _vm.btn_function },
    },
    [
      _c("span", { staticClass: "btn-title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }